import { useBindDispatch } from 'hooks'
import { constants, getConnectUser } from '../helpers'
import OneSignal from 'react-onesignal'

export const useLogout = () => {
    const { logoutDispatch } = useBindDispatch()
    const logout = () => {
        const localStorageData = getConnectUser()
        // clear localStorage & redirect to the login page
        if (localStorageData?.username) {
            localStorage.removeItem(constants.CONNECT_USER)
            localStorage.removeItem(constants.CLIENT_LIST_EXPIRATION_NAME)
            localStorage.removeItem(constants.ENROLLMENT_FORM_CACHED_NAME)

            // logout user
            logoutDispatch()
            window.location.href = '/login'
        }
        // reset active menu
        sessionStorage.removeItem('activeMenu')

        // remove onesignal subscription
        OneSignal.logout()
    }
    return { logout }
}
